.planRow {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.table {
  margin-right: 1rem;
  border-collapse:collapse;
}

.planHeader {
  border: 1px solid black;
  border-bottom: 1px solid darkgray;
}

.planHeader p {
  margin: 0;
}

.planHeaderContainer {
  display: flex;
}

.planInfoHeader {
  width: 70%;
}

.actions {
  width: 30%;
  display: flex;
  flex-direction: column;
  background: orange;
}

.planName {
  font-size: 1.25rem;
  text-align: center;
  font-weight: 900;
  color: white;
}

.bodyHeader th {
  font-weight: lighter;
  font-size: .9rem;
  font-variant: small-caps;
  color: #8c1ca8;
}

.bodyHeader th {
  border-bottom: 3px solid black;
  padding: 0 .5rem;
}

.tableBody {
  border: 1px solid black;
}

.debtRow td {
  padding: .25rem .75rem;
}

.debtRow td:nth-child(3) {
  text-align: right;
}

.tableBody tr:nth-child(even) {
  background-color: lightgray;
}
