@value tablet from from '~styles/screen-sizes.module.css';
.actions {
  composes: flex-right from '~styles/shared.module.scss';
  position: relative;
  width: 100%;
  margin: 0.25rem 0;
}

.login,
.returnButton {
  composes: success from '~styles/buttons.module.css';
  width: 10rem;

  [data-icon='user'] {
    margin-right: 0.5rem;
  }
}

.loginLink,
.businessLink {
  composes: remove-link-style from '~styles/shared.module.scss';
}

.returnButton {
  width: 100%;
  margin: 1.5rem 0;
}

@media tablet {
  .returnButton {
    width: 1rem;
    position: absolute;
    top: 1rem;
    right: 0.5rem;
  }
  .returnText {
    display: none;
  }
}
