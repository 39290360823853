.ieMessage {
  display: none;
  background: yellow;
  padding: .5rem;
  width: 100%;
  text-align: center;
  color: red;
}

@supports not (display: grid) {
  .ieMessage {
    display: initial;
  }
}
