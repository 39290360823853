@value desktop from from '~styles/screen-sizes.module.css';

.container {
  height: 30rem;
  width: calc(100vw - 2rem);
}

@media desktop {
  .container {
    width: calc(100% - 2rem);
  }
}