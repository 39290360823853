@value breakpoints: '~styles/screen-sizes.module.css';
@value mobile, tablet, desktop, desktopMedium from breakpoints;

.modelContainer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9;
  overflow: hidden;
}

.modelContent {
  composes: scale-in-center from '~styles/animations.module.css';
  position: absolute;
  padding: 0 1rem;
  width: calc(100% - 2rem); /*minus total padding*/
  height: 100%;
  background-color: white;
}

.modelTitle {
  composes: h3 from "~styles/shared.module.scss";
  font-size: 1.5rem;
  background-color: var(--model-title-background-color);
  padding: 1rem 1rem;
  margin: 0rem -1rem 1rem -1rem;
  width: 100%;
  color: var(--model-title-text-color);
}

.closeButton {
  position: absolute;
  top: .5rem;
  right: 0.7rem;
  min-width: 2rem;
  font-size: 1.5rem;
  font-weight: 900;
  padding: 0;
  margin: 0;
  border: 0;
  padding: 0;
  background: transparent;
  position: absolute;
  color: var(--model-title-text-color);
}

.modelChildren {
  min-height: 25rem;
  max-height: 90%;
  padding: 0.5rem;
  overflow-y: auto;
}

@media desktop {
  .modelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #625d5dd6;
  }

  .modelContent {
    margin-top: -2.5rem; /*off set for the top starting at 2.5 for nav*/
    height: 80%;
    border-radius: 10px;
  }

  .closeButton {
    top: -0.5rem;
    right: 0.7rem;
  }

  .modelTitle {
    margin: -1rem -1rem 1rem -1rem;
    border-radius: 10px 10px 0 0;
  }
}

@media desktopMedium {
  .modelContent {
    max-width: 50%;
  }
}
