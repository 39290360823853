.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

}
.content {
  display: flex;
  justify-content: space-around;
  align-content: center;
  max-width: 35rem;
  padding: 3rem;
}

.bug {
  color: red;
  font-size: 2rem;
}

.page {
  margin: 0;
}

.messages {
  text-align: center;
}
.message {
  font-weight: 900;
  margin-bottom: .5rem
}

.close {
  font-style: italic;
  margin: 0;
}