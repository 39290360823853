:global ::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  cursor: pointer;
  border-radius: 5px
}

:global ::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 5px;
}