
.container {
  max-width: 1200px;
  margin: auto;
  :global(.PrimaryMenu--actions) {
    display: none;
  }
   p{
     margin: 1rem;
   }

}

.title {
  composes: h1 from "~styles/shared.module.scss";
  display: block;
  margin: 2rem 0;
}

.header {
  composes: h2 from "~styles/shared.module.scss";
  margin: 2rem 0 1rem 0;
  display: block !important;
}

.subject {
  composes: h3 from "~styles/shared.module.scss";
  display: block;
}

.definition {
  padding: 0 .5rem;
}

.action {
  display: flex;
  justify-content: center;
}

.linkButton {
  composes: success from "~styles/buttons.module.css";
  font-size: 1.25rem !important;
  min-width: 15rem;
}