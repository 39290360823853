@value desktop from '~styles/screen-sizes.module.css';

.amount,
.minPayment{
  font-weight: 900;
  color: var(--debt-totals-message-number);
  font-size: 1.25rem;
}

.percentage {
  font-style: italic;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1rem;
}

.container p {
  width: 100%;
  font-size: 1.2rem;
  padding: .5rem;
  text-align: center;
  margin-bottom: 0;
  border: solid 2px darkgrey;
  border-radius: 5px;
  line-height: 1.7rem;

  @media desktop {
    width: 20rem;
  }
}
