.plan {
  composes: box-shadow from '~styles/shared.module.scss';
  width: 18rem;
  border: solid darkgrey 1px;
  border-radius: 10px;
}

.planDescription,
.debtsSummary,
.description {
  padding: 0 0.75rem;
}
.description {
  padding-bottom: .5rem;
}

.contentWrapper {
  padding: 0.75rem;
}

.debtsSummaryHeader,
.planDescriptionHeader {
  composes: flex-space-between from "~styles/shared.module.scss";
}

.showDebtsSummaryButton,
.showDescriptionButton {
  composes: button-with-icon from from "~styles/buttons.module.css";
  color: var(--button-background-color);
}

.label {
  font-weight: 900;
  color: var(--label-color);
}
.amount,
.months {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.length {
  text-align: center;
  font-style: italic;
  font-size: 0.9rem;
}

.planName {
  position: relative;
  padding: 0.5rem 0;
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
  font-weight: 900;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
}

.detailButton {
  width: 100%;
  bottom: 0;
  left: 0;
  background: #fafad28c;
  font-weight: 900;
  width: 100%;
  border: none;
  padding: 0.6rem;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 0.9rem;
}

.detailButton:hover {
  filter: brightness(50%);
  cursor: pointer;
}

.detailButton:focus {
  outline: none;
}
.removePlan {
  position: absolute;
  top: 0.2rem;
  right: 0.5rem;
  border: 0;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 900;
}
