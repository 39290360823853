.row {
  width: 100%;
  border-top: 1px solid darkgray;
  padding: .5rem;
  margin: .5rem 0;
}

.title {
  width: 100%;
  text-overflow: ellipsis;
  margin-bottom: .5rem;
}

.info {
  composes: flex-space-between from from '~styles/shared.module.scss';
  font-size: .95rem;
}