.container {
  margin-bottom: 1rem;
}

.title {
  composes: h2 from '~styles/shared.module.scss';
}

.message {
  margin-top: .5rem;
  color: var(--section-information-color);
}