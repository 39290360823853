@value borderRadius: 8px;

.label {
  font-size: .95rem;
  font-weight: 700;
}

.labelText {
  color: var(--label-color);
}

.input,
.inputTextArea  { 
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid darkgray;
  padding: .25rem;
  width: 100%;
  height: 1.25rem;
  outline: none;
}

.inputTextArea {
  min-height: 1.5rem;
  height: auto;
  font-weight: 300;
  width: 100%;
}

.inputWrapper {
  display: flex;
  margin: .25rem 0;
  padding: 0;
}

.input:focus-within,
.inputTextArea:focus-within {
  border-bottom: 2px solid var(--input-focus-color);
}

.dropDown {
  composes: input;
  width: 100%;
  height: 2rem; /*WHY BUT WHY*/
}



.input.addon {
  display: block;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.inputWrapper button {
  width: 3rem;
  border: none;
  border-bottom: 1px solid darkgray;
  border-left: 1;
}

.helpText {
  margin: 0;
  padding: 0;
  color: darkgray;
  font-size: .9rem;
  font-weight: 400;
  font-style: italic;
}