@value buttonWidth: 8.5rem;

.popupMenu {
  composes: swing-in-right-bck from "~styles/animations.module.css";
  composes: box-shadow from "~styles/shared.module.scss";
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border: solid 1px var(--button-background-color);
  border-radius: 5px;
  width: 13rem;
  white-space: nowrap;
  z-index: 9;

  > li > button,
  > li > a {
    padding: 0;
    border: none;
    background: none;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    :hover {
      font-weight: 700;
    }
    > span {
      flex-basis: 100%;
    }

    [data-icon] {
      margin-right: 0.75rem;
      width: 1.5rem;
    }
  }

  > li {
    padding: 0.5rem 1rem;
  }

  > li:nth-of-type(1) {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  [data-disabled="true"] {
    pointer-events: none; //This makes it not clickable
    opacity: 0.8; //This grays it out to look disabled
    color: darkgray;
  }

  [data-type="title"] {
    font-size: 1.1rem;
    padding: 0.25rem 0.25rem;
    min-width: 9rem;
    margin: 0;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    pointer-events: none; //This makes it not clickable
  }
}

.buttonIcon {
  background: none;
  border: none;
  color: var(--button-background-color);
  font-size: 1.5rem;
}

.buttonIcon:focus {
  outline: none;
  border: 0;
}

.button {
  color: var(--button-background-color);
  border: 1px solid var(--button-background-color);
  padding: 0.25rem 1rem;
  border-radius: 10px;
  width: buttonWidth;
}

.popupMenu {
  [data-icon="folder-plus"] {
    color: red;
  }
  [data-icon="calculator"] {
    color: green;
  }
  [data-icon="sign-out-alt"] {
    color: orange;
  }
  [data-icon="history"],
  [data-icon="building"] {
    color: blue;
  }
  [data-icon="share-alt"] {
    color: purple;
  }
}
