@value desktop from from '~styles/screen-sizes.module.css';

.container {
  margin: 1rem 0;
  width: calc(100vw - 2rem);
}

@media desktop {
  .container {
    width: calc( 100% - 2rem );
  }
}

.header {
  composes: h2 from '~styles/shared.module.scss';
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header button {
  min-width: 4rem;
  font-size: 1rem;
  padding: .4rem;
  border-radius: 5px;
  border: solid 2px;
  font-weight: 600;
  background: teal;
  color: azure;
}

.table {
  font-size: .9rem;
  max-height: 50rem;
  width: 100%;
  display: block;
  overflow: auto;
  border-collapse: collapse;
}

.detailRow:nth-child(even) {
  background-color: lightgrey;
}

.detailRow{
  text-align: right;
}

.detailRow td {
  padding: .3rem;
}

.detailRow td:nth-child(1){
  text-align: left;
  border-left: 1px solid black;
}


.groups th {
  font-size: 1rem;
  position: sticky;
  top: -1px;
  background: white;
  border: 1px solid black;
  border-bottom: none;
}


.columns th {
  min-width: 6.8rem;
  background: white;
  position: sticky;
  top: 0;
  font-weight: 600;
  font-size: .95rem;
  font-variant: small-caps;
  color: #8c1ca8;
  padding: 0 .25rem;
}

.month {
  min-width: 5rem !important;
  border-left: 1px solid black;
}

.firstInGroup {
  border-left: 1px solid black;
}

.lastInGroup {
  border-right: 1px solid black;
}


