.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}

.debtList {
  width: 100%;
}

.content {
  > :global(.DebtForm--container) {
  }

  > :global(.DebtsList--container) {
  }
}
