@value tablet from '~styles/screen-sizes.module.css';

.container {
  width: 100%;
}

@media tablet {

  .formFields {
    display: flex;
    row-gap: 1rem;
    column-gap: 2rem;
    margin-bottom: 1.5rem;

    input {
      background: transparent;
    }
  }

  .amount input,
  .apr input,
  .minPayment input,
  .interest input {
    text-align: right;
  }
  .debtName {
    flex-basis: 40%;
  }
  .amount {
    flex-basis: 30%;
  }
  .minPayment {
    flex-basis: 20%;
  }
  .apr {
    flex-basis: 10%;
  }
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 1rem;
  margin: 1rem 0;
}

.applyButton {
  composes: success from "~styles/buttons.module.css";
}

.removeButton {
  composes: error from "~styles/buttons.module.css";
}