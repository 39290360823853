
.container {
  composes: center-vertical from '~styles/shared.module.scss';
  composes: slide-in-fwd-center from '~styles/animations.module.css';
  text-align: center;
}

.quote {
  font-size: 3rem;
  color: var(--quote-color);
  font-weight: 900;
  font-family: Brush Script MT, Brush Script Std, cursive;
}

.bibleLocation {
  font-weight: 900;
  color: var(--quote-citation-color);
}