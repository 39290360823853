.container {
  composes: full-page-centered-overlay from '~styles/shared.module.scss';
}

.page {
  composes: center-vertical from '~styles/shared.module.scss';
  position: relative;
  background-color: white;
  width: 95vw;
  max-width: 50rem;
  height: 100vh;
  max-height: 50rem;
  border-radius: 10px;
  padding: 1rem;
}

.closeButton {
  composes: text-button from '~styles/buttons.module.css';
  position: absolute;
  top: .25rem;
  right: .5rem;
  color: darkgray;
}

