@value tablet from from '~styles/screen-sizes.module.css';
.error,
.container {
  composes: slide-in-fwd-center from "~styles/animations.module.css";
}

.planOverview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;;
}

.createNewPlan {
  width: 12rem;
}
.amountOptions {
  margin: 0;
}

.amountOptions :global .label {
  display: flex;
  color: black;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;
}

.actionBar {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

@media tablet {
  .actionBar {
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
  }
}

.detailClose {
  display: flex;
  justify-content: flex-end;
}

.detailClose button {
  font-size: 2rem;
  font-weight: 900;
  color: darkgray;
  background: none;
  border: none;
}

.createNewPlan {
  composes: success from "~styles/buttons.module.css";
  margin-right: 1rem;
}

