.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(3, 3, 3, 0.671);
  z-index: 999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.message {
  color: white;
  font-size: 1.25rem;
}

.icon {
  composes: pulsate-bck from '~styles/animations.module.css';
  font-size: 4rem;
  font-weight: 900;
  color: white;
  margin-bottom: 2rem;
}