@value desktop from '~styles/screen-sizes.module.css';

.remove-link-style {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.remove-link-style:visited {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reset-list {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.center-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-vertical-to-horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media desktop {
  .flex-vertical-to-horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.h1 {
  display: inline-block;
  font-size: 2rem;
  font-weight: 900;
}

.h2 {
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 900;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.h3 {
  display: inline-block;
  font-weight: 600;
  font-variant: small-caps;
}

@media desktop {
  .h1 { font-size: 3rem; }
  .h2 { font-size: 2rem; }
  .h3 { font-size: 1.2rem; }
}

.full-page-centered-overlay {
  composes: center-horizontal;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  overflow: hidden;
  overflow-y: hidden;
  background: #625d5dd6;
}

.notification-message {
  padding: 1.25rem;
  color: white;
  border-radius: 10px;
  text-align: center;
}

.notification-message-error {
  composes: notification-message;
  background-color: rgb(185, 94, 94);
}

.notification-message-info {
  composes: notification-message;
  background-color: rgb(92, 49, 247);
}

.box-shadow {
  box-shadow: 2px 2px 5px 1px darkgray;
}

.list-navigation {
  list-style-type: none;
}

.popup-menu {
  composes: swing-in-right-bck from "~styles/animations.module.css";
  composes: box-shadow;
  position: absolute;
  margin: 0;
  padding: 1rem;
  list-style-type: none;
  top: 0;
  right: 0;
  background: white;
  border: solid 1px var(--button-background-color);
  border-radius: 5px;
  width: 13rem;
  white-space: nowrap;
  z-index: 9;
}

.popup-menu-left {
  composes: swing-in-left-bck from "~styles/animations.module.css";
  composes: popup-menu;
  left: 0;
}

.inline-form-entry {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  margin: 0.75rem 0;
  border: 2px solid teal;
  border-radius: 10px;
  padding: 1rem;

  fieldset {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  > :global(.actions) {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-end;
    justify-content: flex-end;
    button {
      width: 100%;
      margin-top: 0.75rem;
    }
  }
}
@media desktop {
  .inline-form-entry {
    flex-direction: row;

    > :global(.actions) {
      button {
        width: 8rem;
        margin: 0;
      }
    }
  }
}
