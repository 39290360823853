/* ----------------------------------------------
 * Generated by Animista on 2021-3-12 16:12:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
.slide-in-fwd-center {
  animation: slide-in-fwd-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s both;
}

@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.swing-in-right-bck {
  animation: swing-in-right-bck 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@keyframes swing-in-right-bck {
  0% {
    transform: rotateY(70deg);
    transform-origin: right;
    opacity: 0;
  }
  100% {
    transform: rotateY(0);
    transform-origin: right;
    opacity: 1;
  }
}

.swing-in-left-bck {
  animation: swing-in-left-bck 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

@keyframes swing-in-left-bck {
  0% {
    transform: rotateY(70deg);
    transform-origin: left;
    opacity: 0;
  }
  100% {
    transform: rotateY(0);
    transform-origin: left;
    opacity: 1;
  }
}

.pulsate-bck {
  animation: pulsate-bck 1s ease-in-out infinite both;
}

@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.scale-in-center {
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
