@value tablet from '~styles/screen-sizes.module.css';

.form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  border: 1px solid lightblue;
  padding: 1rem;
  border-radius: 10px;
}
.timestamp {
  font-size: .9rem;
  font-style: italic;
  text-align: right;
}

.name, 
.description {
  margin-bottom: .75rem;
}
.name {
  flex-basis: 35%;
}

.description {
  flex-basis: 45%;
}

>:global(.buttonToggle) {
  flex-basis: 10%;
}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: .75rem;
    max-height: 2rem;
}

.buttonSave {
  composes: success from '~styles/buttons.module.css';
}
.buttonView {
  composes: warning from '~styles/buttons.module.css';
}
.buttonDelete {
  composes: error from '~styles/buttons.module.css';
}

@media tablet {

  .calcRow {
    flex-basis: 80%;
    column-gap: 2rem;

    // :global(.label-text) {
    //   display: none;
    // }
  }

  [data-form-index="0"] {
    :global(.label-text) {
      display: inline;
    }
  }
}
