@value desktop from '~styles/screen-sizes.module.css';

.container {
  position: relative;
  padding-top: 0.5rem;
  margin-left: -5rem;
  padding-left: 5rem;
  margin-right: -5rem;
  padding-right: 5rem;
  background-color: var(--header-background-color);
  color: var(--header-text-color);
}

@media desktop {
  .primaryMenu {
    position: absolute;
    bottom: 0.75rem;
    right: 5rem;
  }
}

.content {
  composes: flex-vertical-to-horizontal from '~styles/shared.module.scss';

  .about {
    float: right;
    margin-bottom: 1rem;
  }

  > :global(.SocialMedia--container) {
    position: absolute;
    bottom: 0.5rem;
  }

  @media desktop {
    padding-bottom: 0.5rem;
    > :global(.SocialMedia--container) {
      right: 5rem;
    }

    .about {
      margin: 0;
      position: absolute;
      top: 0.5rem;
      right: 5rem;
    }
  }
}

.imageContainer {
  flex-basis: 150px;
  min-width: 150px;
  min-height: 150px;
  display: none;

  @media desktop {
    display: block;
  }
}

.titleContainer {
  flex-basis: 90%;
  @media desktop {
    margin-right: 150px;
  }
}

.title {
  composes: h1 from '~styles/shared.module.scss';
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}

.message {
  text-align: center;
  font-style: italic;
}
.isFetching {
  position: fixed;
  top: 1rem;
  right: 1.5rem;
}
