//TODO: clean up dead styles in this module
@value tablet from from '~styles/screen-sizes.module.css';
.error,
.container {
  composes: slide-in-fwd-center from "~styles/animations.module.css";
}

.planOverview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;;
}

.createNewPlan {
  width: 12rem;
}
.amountOptions {
  margin: 0;
}

.amountOptions :global .label {
  display: flex;
  color: black;
  font-size: 1rem;
  justify-content: space-between;
  align-items: center;
}

.actionBar {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

@media tablet {
  .actionBar {
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem 0;
  }
}

.detailClose {
  display: flex;
  justify-content: flex-end;
}

.detailClose button {
  font-size: 2rem;
  font-weight: 900;
  color: darkgray;
  background: none;
  border: none;
}

.createNewPlan {
  composes: success from "~styles/buttons.module.css";
  margin-right: 1rem;
}

.placeholderContainer  {
  position: relative;
}
.processingOverlay {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  background-color: rgba(3, 3, 3, 0.671);
  padding: 2rem;
  min-width: 25rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  color: white;
  font-size: 1.25rem;
}

.icon {
  composes: pulsate-bck from '~styles/animations.module.css';
  font-size: 4rem;
  font-weight: 900;
  color: white;
  margin-bottom: 2rem;
}

.errorMessage {
  font-size: 1.25rem;
  font-style: italic;
  color: var(--label-color);
  width: 100%;
  text-align: center;

}

.calendar {
  font-size: 5rem;
  margin-bottom: 1rem;
}