.container {
  composes: flex-vertical-to-horizontal from '~styles/shared.module.scss';
  align-items: stretch;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}
.plan {
  composes: flex-vertical from '~styles/shared.module.scss';
  justify-content: space-between;
  min-width: 15rem;
  max-width: 25rem;
  padding: .5rem;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.planName {
  flex-basis: 2rem;
  padding-top: 1rem;
  margin: -.5rem;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 1.25rem;
  color: white;
  font-weight: 900;
}

.description {
  margin: 1rem 0;
  font-size: .9rem;
  flex-basis: 1fr;
}

.addButton {
  composes: success from '~styles/buttons.module.css';
  width: 100%;
  align-self: flex-end;
  justify-content: flex-end;
}