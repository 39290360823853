@value untilTablet from '~styles/screen-sizes.module.css';

.edit {
  text-align: center;
  width: 4rem; //Adjusts to button size

  button {
    border: none;
    background: none;
    color: var(--label-color);
    border-radius: 0;
  }
}

.container {
  margin-top: 1rem;
  width: 100%;
  max-height: 10rem;

  .label {
    display: none;
  }

  td,
  th {
    text-align: right;
    vertical-align: bottom;
    padding: 0.5rem;
    border-bottom: 2px solid darkgray;
  }

  thead th {
    font-weight: 600;
    color: darkgrey;
  }

  .name {
    text-align: left;
  }
}

@media untilTablet {
  .container {
    border-radius: 0;
  }
  .container thead {
    display: none;
  }

  .container tr {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
  }

  .container td {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: right;
    padding: 0.25rem 0.75rem;
  }

  .container td > .label {
    display: inline;
    font-weight: 900;
  }

  .container tr {
    padding: 2rem 0.25rem;
  }
}
