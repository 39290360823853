:root {
  --footer-background-color: #f3d503;
  --footer-text-color: #000000;
  --model-title-background-color: #8c1ca8;
  --model-title-text-color: #ffffff;
  --section-information-color: #8c1ca8;
  --input-focus-color: #ddce00;
  --label-color: #040a76;
  --debt-totals-message-number: #8c1ca8;
  --quote-color: #616161;
  --quote-citation-color: #616161;
  --button-background-color: #14151a;
  --button-text-color: #ffffff;
  --header-background-color: #f3d503;
  --header-text-color: #000000;


  --page-padding: .75rem;
  --business-container-page-height-tablet: 70vh;
  --business-container-page-height-desktop: 83vh;

  --max-debt-list-height: 15rem;
}
