.container {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.views {
  align-self: stretch;
  /* overflow-y: auto;
  max-height: var(--max-debt-list-height); */
}

.form {
  margin-bottom: 1rem;
}