@value desktopMedium, desktop, tablet from '~styles/screen-sizes.module.css';
@value desktop-medium-break from '~styles/screen-sizes.module.css';

.page {
  composes: flex-vertical from "~styles/shared.module.scss";
  position: relative;
  padding: 0 var(--page-padding);
  max-width: desktop-medium-break;
  height: 100vh;
  margin: auto;

  > :global(.Header--container) {
    flex-basis: 10%;
  }

  .container {
    flex-basis: 90%;
  }
}

.error {
  composes: notification-message-error from "~styles/shared.module.scss";
  max-width: 20rem;
}

.container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  > :global(.Plans--placeholderContainer),
  > :global(.Plans--error) {
    margin: auto;
  }

  > :global(.Plans--container) {
    padding: 3rem 1.5rem;
    border: 2px solid darkgray;
    border-radius: 8px;
    margin: 2rem 0;
  }
}

.savedCalcName {
  font-size: .9rem;
  position: relative;
  top: 1rem;
  color: var(--section-information-color);
}


.debtsAndPayment {
  min-height: 15rem;
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  column-gap: 3rem;
  row-gap: 2rem;
  margin: 1rem 0 2rem 0;

  @media desktop {
    flex-direction: row;
  }

  > :global(.Debts--container) {
    height: auto;
    flex-basis: calc(50% - 1.5rem);
    padding: 3rem 1.5rem;
    border: 2px solid darkgray;
    border-radius: 8px;
  }

  > :global(.PaymentSchedule--container) {
    height: auto;
    flex-basis: calc(50% - 1.5rem);
    padding: 3rem 1.5rem;
    border: 2px solid darkgray;
    border-radius: 8px;
  }

}