@value fields-min-width from '~components/Auth/authShared.module.css'; 
.container {
  composes: center-vertical from '~styles/shared.module.scss';
}
.form {
  composes: center-vertical from '~styles/shared.module.scss';
  border: 1px solid darkcyan;
  border-radius: 10px;
  padding: 3rem;
  padding-bottom: 1.5rem;
  max-width: 30rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.legal {
  font-size: .8rem;
  font-style: italic;
  text-align: center;
}

.header {
  font-size: 3rem;
  font-weight: 900;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin: 1rem;
}

.fields {
  margin: 1.5rem 0;
}
.password,
.email,
.fullName {
  min-width: fields-min-width;
}
.password,
.email {
  margin-top: 2rem;
}

.button {
  composes: success from '~styles/buttons.module.css';
  min-width: fields-min-width;
}

.image {
  height: 150px;
  width: 150px;
}
.error {
  composes: notification-message-error from '~styles/shared.module.scss';
  max-width: fields-min-width;
}