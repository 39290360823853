.container {
  padding-right: .75rem;
}

.paymentHeader {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  font-weight: 600;
  color: darkgrey
}


.paymentRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: darkgray 1px solid;
}

.paymentItem,
.paymentItemHeader {
  flex-basis: 70%;
}

.paymentItem :global .money{
  color: var(--section-information-color);
}

.total,
.totalHeader {
  flex-basis: 30%;
  text-align: right;
  padding: 0 .5rem;
}

.totalHeader {
  padding: 0 1rem;
}

.edit,
.editHeader {
  flex-basis: 2rem;
  padding: 0;
}

.edit {
  border: none;
  background: none;
  color: var(--label-color);
}

.removeCircle {
  background-color: darkred;
  color: white;
  font-weight: 900;
  font-size: 1.2rem;
  height: 15px;
  width: 15px;
  line-height: 17px;
  padding: 5px;
  border-radius: 50%;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.remove:focus {
  outline: none;
}

.paymentItem{
  padding: .75rem 0;
}