.placeholder {
  height: 150px;
  width: 150px;
}
.image {
  width: 150px;
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(143, 143, 143);
  color: white;
  font-size: 1.5rem;
}
