/* Tooltip CSS */

[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  transition: 0.6s ease-in-out 0.4s;
}

[data-tooltip]:before {
  content: "";
  height: 0;
  width: 0;
  top: calc(100% - 9px);
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent rgba(51, 51, 51, 0.849) transparent;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  top: calc(100% + 11px);
  font-size: 0.8rem;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  max-width: 13rem;
  min-width: 7rem;
  background: rgba(51, 51, 51, 0.849);
  padding: 0.5rem 1rem 0.5rem 1rem;
  box-sizing: border-box;
  color: #fff;
  border-radius: 4px;
  text-shadow: 0px 1px 1px #000;
  z-index: 999;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  opacity: 1;
  visibility: visible;
  transition: 1s ease-in-out 0.3s;
}

