.button {
  min-width: 4rem;
  font-size: 1rem;
  padding: 0.4rem;
  border-radius: 5px;
  border: solid 2px;
  font-weight: 600;
}

.button:hover {
  filter: brightness(50%);
  cursor: pointer;
}

.button:focus {
  outline-color: black;
}

.button:disabled {
  cursor: not-allowed;
  filter: opacity(50%);
}

.error {
  composes: button;
  background: red;
  border-color: red;
  color: white;
}

.warning {
  composes: button;
  background: rgb(248, 182, 61);
  border-color: rgb(248, 182, 61);
  color: white;
}

.info {
  composes: button;
  background: blue;
  color: white;
}


.success {
  composes: button;
  background: var(--button-background-color);
  border-color: var(--button-background-color);
  color: var(--button-text-color);
}

.spinner {
  border: none;
  color: darkgray;
  padding: 0 0.5rem;
  margin: 0;
  size: 0.7rem;
}

.button-with-icon {
  composes: button;
  composes: flex-space-between from "~styles/shared.module.scss";
  border: none;
  background: none;
  min-width: 5rem;
}

.color-button input {
  border: none;
  background: none;
  padding: 0;
  width: 2rem;
  height: 2rem;
}

.text-button {
  border: none;
  background: none;
  padding: 0;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 900;
}
