.container,
.fields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  :global(.label-text) {
    display: none;
  }
}

.storyLine {
  font-size: 1.1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.amount {
  width: 5.5rem;
}

.amount input,
.frequency select,
.month select,
.year select {
  background-color: transparent;
  text-align: center;
  font-weight: 600;
}

.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 1rem;
  margin: 1rem 0;
}

.submitButton {
  composes: success from "~styles/buttons.module.css";
}

.removeButton {
  composes: error from "~styles/buttons.module.css";
}
