.container {
  background: var(--footer-background-color);
  color: var(--footer-text-color);
  text-align: center;
  width: 100%;
  padding: .2rem 5rem;
  margin: 0 -5rem;
}

.container p {
  margin: .5rem;
}

.message a,
.message a:visited {
  font-size: 1.25rem;
  color: var(--footer-text-color);
}

.copyright {
  font-style: italic;
}

.warning {
  text-align: center;
  width: 100%;
  /* padding: .2rem var(--page-padding); */
  margin: 0 calc(var(--page-padding) * -1); 
  color: yellowgreen;
}