.container {

  :global(.label) {
    font-size: 1rem;
  }
}
.headers {
  text-align: center;
}

.standardPlans {
  margin-bottom: 2rem;
}

.tab,
.tabActive {
  width: 40%;
  margin-bottom: 1rem;
  border: none;
  background: none;
  color: darkgray;
}

.tabActive {
  border-bottom: 2px solid black;
  color: black;
}

.debt {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.header {
  composes: h2 from "~styles/shared.module.scss";
}

.planInput {
  display: flex;
  margin: 1rem 0;
}

.description {
  margin: 1rem 0;
}

.debtList {
  margin: 1rem 0 0 0;
}

.colorSelector {
  margin-left: 1rem;
}
.colorSelector {
  composes: color-button from '~styles/buttons.module.css';
}

.payoffOrder {
  color: var(--label-color);
  font-weight: 900;
  font-size: 1rem;
}

.name,
.debtAmounts {
  margin: 0 2rem;
}

.name {
  font-size: larger;
  font-weight: 600;
}

.debtAmounts label {
  color: purple;
  font-weight: normal;
}

.planName {
  width: 20rem;
}

.saveButton {
  composes: success from "~styles/buttons.module.css";
  width: 10rem;
  height: 3rem;
  float: right;
}
