@value mobile, tablet, desktop, desktopMedium, desktopLarge from from '~styles/screen-sizes.module.css';

:global .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

:global .screenReader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

:global .model-open {
  overflow: hidden;
}


html {
  font-size: 14px;
}


@media mobile {
  :global html {
   font-size: 13px; 
  }
}

@media tablet {
  :global html {
   font-size: 14px; 
  }
}

@media desktop {
  :global html {
   font-size: 15px; 
  }
}

/* @media desktopMedium {
  :global html {
   font-size: 16px; 
  }
}

@media desktopLarge {
  :global html {
   font-size: 17px; 
  }
} */

/***For google tools testing*/
@media desktopMedium {

}

@media desktopLarge {

}